import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth';
import { sendEmailWithResume } from '../../api';

type ButtonRowProps = {
    preview: string;
    loading: boolean;
    currentUserEmail: string | undefined;
    fileLink: string | undefined; // Add fileLink to props type
    template: string;
    toPDF: () => void;
    handlePreview: () => void;
    handleResumeProcess: (fileLink: string) => void;
    handledownloadDocx: () => void;
};

const ButtonRow: React.FC<ButtonRowProps> = ({
    preview,
    loading,
    currentUserEmail,
    fileLink, // Declare fileLink here
    template,
    toPDF,
    handlePreview,
    handleResumeProcess,
    handledownloadDocx
}) => {

    const { currentUser } = useAuth()
    const [sendingMail, setSendingMail] = useState<boolean>(false);

    const handleSendEmail = () => {
        setSendingMail(true)
        sendEmailWithResume({
            "reciever_email": currentUser?.email,
            "file_links": fileLink,
            "template": template,
            "username": currentUser?.username
        }).then((res) => {
            // if (res.status == 200) {
            setSendingMail(false)
            alert("Mail sent successfully")

            // }
        })
    }

    return (
        <div className="row">
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5">
                    {preview == "table" && (
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_assosiate_student"
                        >
                            {!loading ? (
                                <span className="indicator-label">
                                    <KTIcon iconName="file-up" className="fs-3" /> Upload Resume
                                </span>
                            ) : (
                                <span className="indicator-progress" style={{ display: 'block' }}>
                                    Uploading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            )}
                        </button>
                    )}
                    {(preview == "compare" || preview == "download") && (
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px"
                            onClick={handlePreview}
                        >
                            Back to Resumes Library View
                        </button>
                    )}
                </div>
                <div>
                    {(preview == "compare" || preview == "download") && (
                        <>
                            {currentUserEmail === 'vp@thirdeyedata.ai' && fileLink && (
                                <button
                                    type="button"
                                    className="btn btn-primary me-3 my-3 w-200px"
                                    data-bs-toggle="modal"
                                    data-bs-target={
                                        currentUserEmail === 'vp@thirdeyedata.ai' || currentUserEmail === 'forum@thirdeyedata.ai'
                                            ? ''
                                            : '#kt_modal_select_resume'
                                    }
                                    onClick={() => currentUserEmail === 'vp@thirdeyedata.ai' && handleResumeProcess(fileLink)}
                                >
                                    <KTIcon iconName="pencil" className="fs-1" />
                                    Format
                                </button>
                            )}
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => toPDF()}>
                                <KTIcon iconName="cloud-download" className="fs-1" />
                                Download PDF
                            </button>
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => handledownloadDocx()}>
                                <KTIcon iconName="doc" className="fs-1" />
                                Download Docx
                            </button>
                            <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => { handleSendEmail() }}>
                                {!sendingMail ? (
                                    <span className="indicator-label d-flex align-items-center">
                                        <KTIcon iconName="sms" className="fs-1" /> &nbsp;
                                        Email
                                    </span>
                                ) : (
                                    <span className="indicator-progress" style={{ display: 'block' }}>
                                        Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                )}

                            </button>
                        </>
                    )}
                    {
                        currentUser?.usertype == 'superadmin' &&
                        <Link to="/statistics/resume">
                            <button type="button" className="btn btn-primary me-3 my-3 w-200px">
                                <KTIcon iconName="graph-2" className="fs-1" />
                                Analytics
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </div >
    );
};

export default ButtonRow;
